export type ClientPathsKeyType = 'login' | 'accountLogin';
export type ClientPathsType = Record<ClientPathsKeyType, string>;

export type RequestsPathsKeyType = 'myRequestsList';
export type RequestsPathsType = Record<RequestsPathsKeyType, string>;

export type DevolutionPathsKeyType = 'eligibleProducts' | 'createDevolution';
export type DevolutionPathsType = Record<DevolutionPathsKeyType, string>;

type CorePathsType = {
  client: ClientPathsType;
  requests: RequestsPathsType;
  devolution: DevolutionPathsType;
};

const AUTH_API = '/api/auth';
const ACCOUNT_AUTH_API = '/api/auth/nike-account';
const MY_REQUESTS_LIST = '/api/exchangeOrder';
const ELIGIBLE_PRODUCTS = '/api/devolution/productsEligible';
const CREATE_DEVOLUTION = '/api/devolution';

/**
This function is used for API paths (API url's parts).
It's necessary to separate into contexts, as well as “customer” and “checkout”, which are already created.
If necessary, create a new context following the pattern.
 */

export const getCorePaths = (): CorePathsType => ({
  client: {
    login: AUTH_API,
    accountLogin: ACCOUNT_AUTH_API,
  },
  requests: {
    myRequestsList: MY_REQUESTS_LIST,
  },
  devolution: {
    eligibleProducts: ELIGIBLE_PRODUCTS,
    createDevolution: CREATE_DEVOLUTION,
  },
});
