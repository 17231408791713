import { Typography } from '@grupo-sbf/motriz-nike';
import { useRouter } from 'next/router';
import { getCoreRoutes } from '@/src/common/config';
import { useRequest } from '@/src/common/contexts/RequestContext';
import { dateFormatter } from '@/src/common/utils/formatter';
import {
  ContentContainer,
  StyledCardRequest,
  RedirectIcon,
  BoxInfo,
} from './CardRequest.styled';

export type CardRequestProps = {
  protocol: string;
  modelDescription: string;
  exchangedDate: string;
  exchange: object;
  tagCode: string;
};

const CardRequest = ({
  protocol,
  modelDescription,
  exchangedDate,
  exchange,
  tagCode,
}: CardRequestProps) => {
  const { setRequestPartialValues } = useRequest();
  const router = useRouter();
  const coreRoutes = getCoreRoutes();

  const goToDetails = () => {
    setRequestPartialValues(exchange);
    router.push(`${coreRoutes.requestDetails}`);
  };

  return (
    <StyledCardRequest>
      <ContentContainer onClick={() => goToDetails()} role="button">
        <Typography type="paragraph" weight="normal">
          Solicitação {protocol}
        </Typography>
        <RedirectIcon />
        <BoxInfo>
          <Typography type="paragraph" weight="intense">
            Produto selecionado:
          </Typography>
          <Typography type="paragraph" weight="normal">
            {modelDescription}
          </Typography>
        </BoxInfo>
        <BoxInfo>
          <Typography type="paragraph" weight="intense">
            Data de solicitação:
          </Typography>
          <Typography type="paragraph" weight="normal">
            {dateFormatter(exchangedDate)}
          </Typography>
          <Typography type="paragraph" weight="intense" mt={100}>
            Código de postagem:
          </Typography>
          <Typography type="paragraph" weight="normal">
            {tagCode || 'Aguardando código'}
          </Typography>
        </BoxInfo>
      </ContentContainer>
    </StyledCardRequest>
  );
};

export default CardRequest;
