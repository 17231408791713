import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { getCorePaths } from '@/src/common/config';
import { getClientHeaders } from '@/src/common/services/api/client';
import api from '../../api';
import { MyRequestListResponse } from './myRequestsList.types';

const myRequestsListPath = getCorePaths().requests.myRequestsList;

export const getMyRequestsList = async (listingQuantity: number) => {
  const requestsListURI = `${myRequestsListPath}?numberOfRecords=${listingQuantity}`;

  return api.get<MyRequestListResponse>(requestsListURI, {
    headers: {
      ...getClientHeaders(),
    },
  });
};

export const useMyRequestsList = (listingQuantity: number) => {
  return useQuery<MyRequestListResponse, AxiosError>(
    'myRequestsList',
    async () => {
      const response = await getMyRequestsList(listingQuantity);
      return response.data;
    },
    {
      refetchOnMount: 'always',
    },
  );
};

export default useMyRequestsList;
