import { useSession } from 'next-auth/react';
import { parseCookies } from 'nookies';
import { useEffect } from 'react';
import { setStorageItem } from '../utils/localStorage';

export const useAuthSession = () => {
  const { data: session, status } = useSession({ required: false });
  const isAuthenticated = status === 'authenticated';
  const isUnauthenticated = status === 'unauthenticated';
  const isLoading = status === 'loading';
  const accessToken = session?.user?.accessToken;
  const clientName = session?.user?.clientName;
  const tokenAuthNike = parseCookies()['access-token'];

  useEffect(() => {
    if (accessToken) {
      setStorageItem('x-acess-token', accessToken);
    }
  }, [accessToken]);

  return {
    isAuthenticated,
    isUnauthenticated,
    isLoading,
    tokenAuthNike,
    clientName,
  };
};

export default useAuthSession;
