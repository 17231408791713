import { ArrowRight } from '@grupo-sbf/motriz-icons';
import { media } from '@grupo-sbf/motriz-utils';
import styled, { css } from 'styled-components';

export const StyledCardRequest = styled.div`
  ${({ theme }) => css`
    border-color: ${theme.color.neutral[400]};
    border-radius: ${theme.borderRadius.large};
    border-style: solid;
    border-width: ${theme.borderWidth.xthin};
    box-shadow: ${theme.shadow[100]};
    font-size: ${theme.fontSize.small};
    line-height: ${theme.lineHeight.normal};
    padding: ${theme.spacing[300]};
    text-align: initial;
    cursor: pointer;
  `}
`;

export const ContentContainer = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 1fr 1fr 25px;
    row-gap: ${theme.spacing[200]};
    column-gap: ${theme.spacing[200]};

    > :first-child {
      grid-column: 1 / 3;
    }

    ${media.lessThan('large')} {
      gap: ${theme.spacing[300]};
    }
  `}
`;

export const RedirectIcon = styled(ArrowRight)`
  align-self: center;
  grid-row: span 2;
`;

export const BoxInfo = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing[100]};
  `}
`;
