import { Typography } from '@grupo-sbf/motriz-nike';
import { media } from '@grupo-sbf/motriz-utils';
import styled, { css } from 'styled-components';

export const RequestsContentStyled = styled.div`
  ${({ theme }) => css`
    max-width: 902px;
    margin: 0 auto;
    padding: ${theme.spacing[700]} ${theme.spacing[300]};
  `}
`;

export const ContainerHeader = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing[300]};
    margin: ${theme.spacing[300]} ${theme.spacing[0]} ${theme.spacing[700]};

    ${media.lessThan('large')} {
      gap: ${theme.spacing[0]};
      margin: ${theme.spacing[600]} ${theme.spacing[0]};
    }
  `}
`;

export const Title = styled(Typography).attrs({
  type: 'subtitle',
})`
  ${({ theme }) => css`
    padding: ${theme.spacing[200]} ${theme.spacing[600]};
    text-align: center;
  `}
`;

export const SubTitle = styled(Typography).attrs({
  type: 'paragraph',
  weight: 'normal',
})`
  ${({ theme }) => css`
    padding: ${theme.spacing[100]};
    text-align: center;
    display: inline;
  `}
`;

export const GridRequest = styled.div`
  ${({ theme }) => css`
    display: grid;
    gap: ${theme.spacing[200]};
    grid-template-columns: 1fr;
    margin-bottom: ${theme.spacing[1000]};

    ${media.greaterThan('medium')} {
      grid-template-columns: repeat(2, 1fr);
      gap: ${theme.spacing[400]};
    }
  `}
`;

export const EmptyMessage = styled(Typography).attrs({
  type: 'paragraph',
})`
  ${({ theme }) => css`
    box-sizing: border-box;
    color: ${theme.color.neutral[400]};
    display: grid;
    place-items: center;
    padding: ${theme.spacing[300]};
  `}
`;
