import axios, { AxiosRequestConfig } from 'axios';

export const corsRequestConfig = (config: AxiosRequestConfig) => {
  const newConfig = { ...config };

  return newConfig;
};

const api = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
});

api.interceptors.request.use(corsRequestConfig);

export default api;
