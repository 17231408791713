import { Loading } from '@grupo-sbf/motriz-icons';
import { Typography } from '@grupo-sbf/motriz-nike';
import { hexToRGBA, media } from '@grupo-sbf/motriz-utils';
import styled, { css } from 'styled-components';

type LoaderProps = {
  isFullScreen?: boolean;
  isFullHeight?: boolean;
};

const PageLoaderContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: ${theme.spacing[600]};
    left: 0;
    bottom: 0;
    right: 0;
    z-index: ${theme.zIndex[300]};
    background: ${hexToRGBA(theme.color.neutral[100], theme.opacity[600])};

    ${media.greaterThan('large')} {
      top: ${theme.spacing[1200]};
    }
  `}
`;

const ComponentLoaderContainer = styled.div<LoaderProps>`
  ${({ isFullHeight }) => css`
    height: ${isFullHeight ? '100vh' : 'auto'};
    min-height: auto;
    align-items: center;
    display: flex;
    justify-content: center;
  `}
`;

const BoxLoader = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    color: ${theme.color.neutral[700]};
    position: relative;
    top: -15%;
  `}
`;

const LoadingStyled = styled(Loading)`
  will-change: transform;

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }

  animation: rotation 1.5s infinite linear;
`;

const LoadingTypographyStyled = styled(Typography)`
  ${({ theme }) => css`
    color: ${theme.color.neutral[700]};
    padding-top: ${theme.spacing[200]};

    ${media.greaterThan('large')} {
      padding-top: ${theme.spacing[400]};
    }
  `}
`;

const ComponentBoxLoader = (
  <BoxLoader>
    <LoadingStyled titleAccess="carregando o conteúdo" />
    <LoadingTypographyStyled type="subtitle">
      Carregando
    </LoadingTypographyStyled>
  </BoxLoader>
);

const Loader = ({ isFullScreen = true, isFullHeight = true }: LoaderProps) => {
  const ComponentLoader = () => (
    <ComponentLoaderContainer isFullHeight={isFullHeight}>
      {ComponentBoxLoader}
    </ComponentLoaderContainer>
  );

  const PageLoader = () => (
    <PageLoaderContainer>{ComponentBoxLoader}</PageLoaderContainer>
  );

  const Container = isFullScreen ? PageLoader : ComponentLoader;

  return <Container />;
};

export default Loader;
